import React, { Fragment, useState } from 'react';
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import SectionTitle from '../../components/Shared/SectionTitle';
import { Link } from 'react-router-dom';
import { products } from './ProductsList';
import { IoCloseSharp } from "react-icons/io5";

const OurProducts = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState({});

  const [col, setCol] = useState(4);
  if(products.length <= 2){
    setCol(6)
  }else if( 7 > products.length > 2){
    setCol(4)
  }
  
  return (
    <Fragment>
      <Container className="mt-100 mt-60">
        <SectionTitle
          title="Our Products"
          desc=" that can provide everything you need to generate awareness, drive traffic, connect."
        />
      </Container>
      <Container fluid className="mb-md-5">
        <Row className='justify-content-around mx-2'>
          {products.map((product, key) => (
            <Col key={key} xl={col} lg={col} md={6} sm={12} className='text-center mb-5'>
              <Card className="border-0 work-grid d-block mx-1 shadow-lg">
                <CardBody className="p-0">
                  <div
                    className="d-inline-block w-100"
                  >
                    <img
                      src={product.img}
                      className="img-fluid shadow rounded w-100 custom_image_pixel"
                      alt=""
                    />
                    <div>
                      <h4 className="mt-1 text-dark title">
                        {product.title}
                      </h4>
                    </div>
                    <h6 href='#details' className='btn btn-md btn-primary mb-4' 
                    onClick={()=>{
                      setIsOpen(true)
                      setActiveProduct(product)
                    }}>
                      Read More
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Modal
        role="dialog"
        isOpen={isOpen}
        centered
        className='position-relative'
        size="xl"
      >
        <ModalHeader>
          {activeProduct.title}
          <IoCloseSharp size={26} onClick={()=>setIsOpen(false)} cursor={'pointer'} className='modal_custom_close'/>
        </ModalHeader>

        <ModalBody>
          <div className="bg-white p-3 rounded box-shadow">
            bdoskjslofjk
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default OurProducts;
