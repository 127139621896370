import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "reactstrap";

const team = [
  {
    name : 'Mohamed Abo-daif',
    role: 'C.E.O' ,
    img: '' ,
  },
  {
    name : 'Chems Eddine',
    role: 'Team Leader' ,
    img: require ('../../assets/images/chems.jpg') ,
  },
  {
    name : 'Muhammed ALDRUBİ',
    role: 'Back-end Developer' ,
    img: require ('../../assets/images/Drubi.jpg') ,
  },
  {
    name : 'Mohamed Atef',
    role: 'Ful-stack Developer' ,
    img: '' ,
  },
  {
    name : 'Usman Fathy',
    role: 'Back-end Developer' ,
    img: require ('../../assets/images/usman.jpg') ,
  },
]

const Team = () => {
  return (
    <Fragment>
      <Container className="mt-100 mt-60">
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-4">Our Mind Power</h4>
              <p className="text-muted para-desc mx-auto mb-0">
                Start working with{" "}
                <span className="text-primary fw-bold">
                  Pixel
                </span>{" "}
                that can provide everything you need to generate awareness,
                drive traffic, connect.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {
            team.map((item , key)=>{
              return(
                <Col lg={4} md={4} className="mt-4 pt-2" key={key}>
                  <Card className="team-primary text-center border-0">
                    <div className="position-relative">
                      <img
                        src={item.img}
                        className="avatar avatar-ex-large rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="card-body py-3 px-0 content">
                      <h5 className="mb-0">
                        <h5 className="name text-dark">{item.name}</h5>
                      </h5>
                      <small className="designation text-muted">{item.role}</small>
                    </div>
                  </Card>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </Fragment>
  );  
};
export default Team;
