import React, { Fragment } from "react";
import { Container, Row, Col  } from "reactstrap";
import video from '../../assets/images/Pexel.mp4'

const Section = () => {
  return (
    <Fragment>
      <section className="swiper-slider-hero position-relative d-block position-relative">
        <video autoPlay style={{minWidth:'100vw'}} muted loop src={video} className="position-absolute" /> 
        <div className="bg-overlay"></div>
        <Container style={{height:'100vh'}} className="slide-inner slide-bg-image d-flex justify-content-center align-items-center">
          <Row className="justify-content-center">
            <Col lg="12">
              <div className="title-heading text-center">
                <h1 className="heading text-white title-dark mb-4">
                  Innovative Solutions 
                  <br />
                </h1>
                <p className="para-desc mx-auto text-light">
                  We are passionate about developing cutting-edge software solutions that integrate with industrial operation, prioritizing safety and enhance efficiency
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default Section;
